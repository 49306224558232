<template>
    <v-container>
        <base-breadcrumb :title="page.title" :breadcrumbs="breadcrumbs"></base-breadcrumb>
        <base-card heading="Usuários">
        <template slot="customButton">
          <v-btn @click="exportAsCsv()" class="mr-3" color="primary">
            <v-icon small class="mr-1">mdi-account-multiple-outline</v-icon>
            Exportar
          </v-btn>
           <v-btn color="primary" @click="openCreateUser()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
             Usuário
           </v-btn>
        </template>
        <datatable-list ref="userList" v-if="!isLoadingUsers"
            :showSearch="true"
            searchHint="Clique no usuário desejado para abrir o popup de edição."
            :text="text"
            :headers="headers"
            @search="search=$event"
            :showClearFilter="true"
            @clearFilter="search=''"
            :items="items"
            :displayActionItems="false"
            :pagination="pagination"
            @update="pagination = { ...pagination, ...$event}"
            @actionButton="actionButton($event)"
            @select-item="edit($event)"
            @activateUser="openIsToEnableUserDialog($event, true)"
            @deactivateUser="openIsToEnableUserDialog($event)"
        >
        </datatable-list>
        <v-skeleton-loader v-else
            v-bind="attrs"
            type="article"
        ></v-skeleton-loader>
      </base-card>
      <dialog-base-form
      v-model="dialogDeactivateUser"
      title="Inativação de usuário"
      okBtn="Inativar"
      :sending="sending"
      :width="'350px'"
      @action="updateIsEnableUser()"
      @close="dialogDeactivateUser=false"
      cancelBtn="Cancelar">
        <div class="tw-flex tw-items-center">
          <v-icon large color="yellow darken-3" class="mr-3">mdi-alert</v-icon>
          <h2 class="py-3">Esta ação irá inativar este usuário, você tem certeza?</h2>
        </div>
      </dialog-base-form>
      <dialog-base-form
      v-model="dialogActivateUser"
      title="Ativação de usuário"
      okBtn="Ativar"
      :width="'350px'"
      :sending="sending"
      @action="updateIsEnableUser(true)"
      @close="dialogActivateUser=false"
      cancelBtn="Cancelar">
        <div class="tw-flex tw-items-center">
          <v-icon large color="yellow darken-3" class="mr-3">mdi-alert</v-icon>
          <h2 class="py-3">Esta ação irá ativar este usuário, você tem certeza?</h2>
        </div>
      </dialog-base-form>
    </v-container>
</template>

<script>
import DialogBaseForm from '@/components/dialog/DialogBaseForm.vue'
import collect from 'collect.js'
import { roles } from '@/constants/roles'

export default {
  components: { DialogBaseForm },
  data: () => ({
    isLoadingUsers: Boolean,
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 0
    },
    page: {
      title: 'Usuários'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuários',
        disabled: true,
        to: '/users'
      }
    ],
    text: {
      searchLabel: 'Pesquisar usuários',
      emptyLabel: 'Nenhum usuários encontrado',
      addLabel: 'Adicionar usuários',
      addUrl: '/users/create',
      importLabel: '',
      importUrl: ''
    },
    headers: [
      { text: 'Nome', align: 'start', value: 'name' },
      { text: 'E-mail', value: 'email', sortable: false },
      { text: 'Ações', value: 'buttons', sortable: false }
    ],
    items: [],
    pagination: {
      page: 1,
      itemsPerPage: 10,
      prev_page_url: false,
      next_page_url: false
    },
    organizationId: '',
    dialog: false,
    dialogActivateUser: false,
    dialogDeactivateUser: false,
    search: '',
    roles,
    selectedId: '',
    sending: false
  }),
  async created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.getUsers()
  },
  watch: {
    async 'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        await this.getUsers()
      }
    },
    async search (value) {
      this.pagination.page = 1
      await this.getUsers()
      this.search = value
    }
  },
  methods: {
    handleError (error = null) {
      collect(error.errors).each(msg => {
        this.$store.dispatch('alert', { color: 'red', msg: msg[0] })
      })
    },
    async getUsers () {
      this.isLoadingUsers = true
      this.items = []
      this.$axios.get(
        `/organizations/${this.organizationId}/users?page=${this.pagination.page}&search=${this.search}`
      )
        .then(response => response.data)
        .then(async users => {
          collect(users.items).map(user => {
            this.items.push({
              id: user.metadata.id,
              name: user.data.name,
              email: user.data.email,
              createTime: user.metadata.createTime,
              is_enabled: user.data.is_enabled,
              is_force_update_password: user.data.is_force_update_password
            })
          }).all()

          const pagination = users.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page,
            itemsPerPage: pagination.per_page
          }
        })
        .finally(() => (this.isLoadingUsers = false))
    },
    edit (item) {
      this.$router.push({ path: `/users/${item.id}/edit` })
    },
    openIsToEnableUserDialog (id, isToEnable = false) {
      this.selectedId = id
      if (!isToEnable) {
        this.dialogDeactivateUser = true
        return
      }
      this.dialogActivateUser = true
    },
    updateIsEnableUser (condition = false) {
      this.$axios.patch(
        `/organizations/${this.organizationId}/users/${this.selectedId}`,
        {
          is_enabled: condition
        }
      ).then(async () => {
        this.$store.dispatch('alert', { msg: `Usuário ${condition ? 'ativado' : 'inativado'} com sucesso!` })
        await this.getUsers()
      })
        .catch(error => {
          this.handleError(error.response.data)
        })
        .finally(() => {
          this.dialogActivateUser = false
          this.dialogDeactivateUser = false
        })
    },
    exportAsCsv () {
      const data = []
      let csv = 'Nome, Email, Situação\n'
      this.items.forEach(item => {
        data.push([
          item.name,
          item.email,
          item.is_enabled ? 'Ativo' : 'Inativo'
        ])
      })
      data.forEach(item => {
        csv += `${item.join(',')}\n`
      })
      const exportElement = document.createElement('a')
      exportElement.href = 'data:text/csv;charset=utf-8,' + '\ufeff' + decodeURI(csv)
      exportElement.target = '_blank'
      exportElement.download = 'lista_de_usuarios.csv'
      exportElement.click()
    },
    openCreateUser () {
      this.$router.push({ path: '/users/create' })
    }
  }
}
</script>
